<template>
    <div class="p-grid panel-demo">
        <div class="p-col-12 p-md-12">
            <div class="flexed-container card p-fluid">
                <h5>Welcome to {{ channelData?.data?.data?.subnetwork_name && channelData?.data?.data?.subnetwork_name.length > 0 ? channelData?.data?.data?.subnetwork_name : 'Channel' }} Home!</h5>
                <Button v-if="isAdmin" class="delete-channel-button" @click="confirmDelete">
                   Delete Channel
                </Button>
            </div>
        </div>

        <div class="p-col-12 p-md-12">
            <div class="card p-fluid">
                <div><img id="logo" :src="channelData?.data?.data?.subnetwork_logo?.attachments?.length > 0
                    ? channelData.data.data.subnetwork_logo.attachments[0]
                    : 'assets/layout/images/arenacx_teal_logo.png'" alt="logo" style="height: 12.25rem" /></div>
            </div>
        </div>
    </div>

    <div v-if="!isProd" class="p-grid">
        <div class="p-col-12 p-md-12">
            <div class="card p-fluid">
                <h4>RFP Insights</h4>
            </div>
            <div class="card p-fluid">
                <div class="calender-wrapper">
                    <div class="calendar-container">
                        <Calendar v-model="dates" selectionMode="range" :manualInput="false"
                            :date-format="dd / MM / yyyy" showButtonBar />
                    </div>
                    <Button :disabled="!dates || dates?.length == 0 || dates[0] == null || dates[1] == null"
                        @click="getRfpListInsights">Get Data</Button>

                </div>

                <DataTable :value="products" showGridlines :scrollable="true" responsiveLayout="scroll" stripedRows
                    scrollHeight="500px" :loading="loading" :resizableColumns="true">

                    <template #empty>
                        <div class="empty-state">
                            <i class="pi pi-info-circle text-gray-500 text-xl mb-2"></i>
                            <p class="m-0">No records found for the selected date range</p>
                        </div>
                    </template>

                    <Column field="id" header="ID" headerStyle="width: 115px;" sortable></Column>
                    <Column field="name" sortable>
                        <template #header>
                            Name
                            <span style="margin-left: 12px;font-size: 0.8em; font-weight:normal;"> (Click to view
                                details)</span>
                        </template>
                        <template #body="slotProps">
                            <div @click="navigateToRfpDetails(slotProps.data.id)" class="opportunityLink">
                                {{ slotProps.data.name }}
                            </div>
                        </template>
                    </Column>
                    <Column field="email" header="Email" sortable></Column>
                    <Column field="status_display_name" header="Status" sortable></Column>
                    <Column field="tags.service" header="Service" sortable></Column>
                    <Column field="tags.industry" header="Industry" sortable></Column>
                </DataTable>
            </div>
        </div>
    </div>

    <!-- Add Dialog component -->
    <Dialog v-model:visible="deleteDialog" modal header="Confirm Deletion" :style="{ width: '350px' }">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem; color: var(--yellow-700)" />
            <span>Are you sure you want to delete this channel?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" @click="deleteDialog = false" class="p-button-text"/>
            <Button label="Yes" icon="pi pi-check" @click="deleteChannel" class="p-button-danger" />
        </template>
    </Dialog>
</template>

<script>

import CurrentUserService from './../service/CurrentUserService';
import RfpInsightsService from './../service/RfpInsightsService';
import ChannelService from './../service/ChannelService';
import { getAccount } from '../service/common';
export default {
    data() {
        return {
            products: null,
            dates: null,
            listPayload: null,
            userData: null,
            channelData: null,
            deleteDialog: false,
            isAdmin: false,
            subnetworkId: null,
            isProd: false,

        };
    },

    productService: null,
    rfpInsightsService: null,
    userService: null,
    channelService: null,

    async created() {
        this.userService = new CurrentUserService();
        this.rfpInsightsService = new RfpInsightsService();
        this.channelService = new ChannelService();
        this.userData = this.userService.marketplaceUser;
        this.isAdmin = getAccount();
        this.subnetworkId = localStorage.getItem('subnetworkId');
        console.log("User Data", this.isAdmin, this.subnetworkId);
        this.channelData = await this.channelService.getChannelData(this.isAdmin ? this.subnetworkId : this.userData.subnetworkId);
        this.setInitialDateRange();
        this.isProd = process.env.VUE_APP_ENV_TYPE === "PROD";
    },

    methods: {

        // Navigate to RFP details page
        navigateToRfpDetails(id) {
            if (process.env.VUE_APP_ENV_TYPE == "DEV") {
                window.open(`${process.env.VUE_APP_RFPERFECT_FRONTEND_URL}/details?id=${id}`, '_blank');
            } else if (process.env.VUE_APP_ENV_TYPE == "UAT") {
                window.open(`${process.env.VUE_APP_RFPERFECT_FRONTEND_URL}/details?id=${id}`, '_blank');
            } else if (process.env.VUE_APP_ENV_TYPE == "PROD") {
                window.open(`${process.env.VUE_APP_RFPERFECT_FRONTEND_URL}/details?id=${id}`, '_blank');
            }
        },

        // Set initial date range
        setInitialDateRange() {
            const today = new Date();
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

            this.dates = [firstDayOfMonth, today];
            this.getRfpListInsights(); // Call the API with initial date range
        },

        // Get RFP list insights
        async getRfpListInsights() {
            this.listPayload = {
                startDate: this.dates[0]?.toISOString(),  // First date in array
                endDate: this.dates[1]?.toISOString(),  // Second date in array
                channelId: this.isAdmin ? this.subnetworkId : this.userData.subnetworkId,
            }
            const res = await this.rfpInsightsService.getRfpListInsights(this.listPayload);
            this.products = res.data;
        },

        confirmDelete() {
            this.deleteDialog = true;
        },

        deleteChannel() {
            // TODO: Implement channel deletion logic here
            this.deleteDialog = false;
        },
    },
};
</script>

<style scoped lang="scss">
.para {
    line-height: 1.5;
    margin: 0;
}

.opportunityLink {
    color: darkgreen;
    text-decoration: underline;
    cursor: pointer;
}

.calender-wrapper {
    display: flex;
    margin-top: 10px;
    margin-bottom: 16px;
    gap: 10px;
}

.calendar-container {
    width: 200px;
}

.empty-state {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.flexed-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-channel-button {
   width: 120px !important;
   text-align: center;
   justify-content: center;
}

.delete-channel-button:hover {
    background-color: red;
    color: white;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

</style>
